import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sqg-stepper" }
const _hoisted_2 = { class: "sqg-stepper__round" }
const _hoisted_3 = {
  key: 0,
  class: "fas fa-check"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(`sqg-stepper__step -${step.state}`),
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, [
          (step.state === 'done')
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : _createCommentVNode("", true),
          (step.state === 'inProgress')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(step.step), 1))
            : _createCommentVNode("", true),
          (step.state === 'todo')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(step.step), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(`sqg-stepper__title -${step.state}`)
        }, _toDisplayString(step.name), 3)
      ], 2))
    }), 128))
  ]))
}