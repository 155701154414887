
import {defineAsyncComponent, defineComponent} from "vue";

export default defineComponent({
  name: "Stepper",
  components: {
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
  },
  props: {
    steps: { type: Array, default: () => [] },
  }
});
